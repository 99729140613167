<template>
  <ContentHeader />
  <div class="container py-4" v-if="eventError">{{ eventError }}</div>
  <div class="content-wrap container">
    <router-view
      @setevent="reload"
      :event="eventObj"
      @openPopup="popUpOpen = true"
      @openPriceList="priceListOpen = true"
    />
  </div>
  <PassedThrough v-if="popUpOpen" @closePopup="popUpOpen = false" />
  <PriceList v-show="priceListOpen" @closePriceList="priceListOpen = false" />
  <ContentFooter />
</template>

<script>
import ContentHeader from "@/layout/ContentHeader";
import ContentFooter from "@/layout/ContentFooter";
import PassedThrough from "@/components/PassedThrough";
import PriceList from "@/components/PriceList";
import axios from "axios";
import getCollection from "@/composables/getCollection";
import getLiveEvent from "@/composables/getLiveEvent";
// import getEvent from '@/composables/getEvent'
import { ref, provide } from "vue";

export default {
  components: {
    ContentFooter,
    ContentHeader,
    PassedThrough,
    PriceList,
  },
  setup() {
    const popUpOpen = ref(false);
    const priceListOpen = ref(false);
    // const store = useStore();
    const eventObj = ref(null);

    provide("event", eventObj);
    // const eventError = ref(false)
    const wpdata = ref([]);

    const { error, documents } = getCollection("lots");

    provide("livedata", documents);
    provide("wpdata", wpdata);

    const { event, eventError, loadEvent } = getLiveEvent();

    const reload = () => {
      loadEvent().then((res) => {
        if (event.value) {
          eventError.value = null;
          axios
            .get("https://bsa.co.za/wp-json/wp_event_lots/v1/all-lots", {
              params: {
                id: event.value.id,
              },
            })
            .then((res) => {
              wpdata.value = res.data.data.lots;
              eventObj.value = res.data.data.event;
            });
        }
      });
    };

    reload();

    return {
      documents,
      error,
      wpdata,
      eventError,
      eventObj,
      reload,
      popUpOpen,
      priceListOpen,
      // livedatafilter,
    };
  },
};
</script>
