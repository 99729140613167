<template>
  <div class="popup-wrap">
    <div class="popup-inner">
      <div class="popup-body">
        <a @click.prevent="$emit('closePopup')" class="popup-close"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path
              d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"
            /></svg
        ></a>
        <widget heading="Passed Through">
          <bsa-table v-if="passed" :lots="passed" :fields="fields"></bsa-table>
        </widget>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject } from "vue";
import BsaTable from "@/components/BsaTable.vue";

export default {
  components: {
    BsaTable,
  },
  setup() {
    const fields = [
      {
        name: "lotNumber",
        title: "Lot",
        width: "10%",
      },
      {
        name: "name",
        width: "30%",
        title: "Horse",
      },
      {
        name: "type",
        width: "20%",
        title: "Status",
        formatOutput(value) {
          return value != "NO_SALE" ? value : "NO SALE";
        },
      },
      {
        name: "sire",
        width: "20%",
        title: "Sire",
      },
      {
        name: "reservePrice",
        width: "20%",
        title: "Reserve Price",
        formatOutput(value) {
          return new Intl.NumberFormat().format(value);
        },
      },
      //   {
      //     name: "soldPrice",
      //     title: "Price",
      //     width: "30%",
      //     formatOutput(value) {
      //       return new Intl.NumberFormat().format(value);
      //     },
      //   },
    ];
    const livedata = inject("livedata");

    const passed = computed(() => {
      if (livedata.value.length) {
        return livedata.value.filter(
          (item) => item.type == "NO_SALE" && !item.current
        );
      }
    });

    return { passed, fields };
  },
};
</script>

<style>
.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #ffffff;
}
</style>
