import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {AppAuth} from './firebase/config'
import './assets/css/normalize.min.css'
import './assets/css/main.css'

import Widget from '@/components/Widget.vue'

let app;
AppAuth.onAuthStateChanged(() => {
    if(!app){
        app = createApp(App).use(store).use(router)
              app.component('Widget',Widget)
              app.mount('#app')
    }
})
