<template>
  <div class="popup-wrap">
    <div class="popup-inner">
      <div class="popup-body">
        <a @click.prevent="$emit('closePriceList')" class="popup-close"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path
              d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"
            /></svg
        ></a>
        <widget heading="Price List">
          <bsa-table
            v-if="priceList"
            :lots="priceList"
            :fields="fields"
          ></bsa-table>
        </widget>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject } from "vue";
import BsaTable from "@/components/BsaTable.vue";

export default {
  components: {
    BsaTable,
  },
  setup() {
    const fields = [
      {
        name: "lotNumber",
        title: "Lot",
        width: "6%",
      },
      {
        name: "name",
        width: "20%",
        title: "Horse",
      },
      {
        name: "vendor",
        width: "19%",
      },
      {
        name: "buyer",
        width: "19%",
      },
      {
        name: "reservePrice",
        width: "18%",
        title: "Reserve Price",
        formatOutput(value, lot) {
          // console.log(lot.soldPrice);
          if (lot.type != "NO_SALE" && lot.reservePrice == 0) {
            return "-";
          } else {
            return new Intl.NumberFormat().format(value);
          }
        },
      },
      {
        name: "soldPrice",
        title: "Price",
        width: "18%",
        formatOutput(value, lot) {
          if (lot.type == "WITHDRAWN") {
            return "W/D";
          } else {
            return new Intl.NumberFormat().format(value);
          }
        },
      },
    ];
    const livedata = inject("livedata");

    const priceList = computed(() => {
      if (livedata.value.length) {
        return livedata.value.filter((item) => !item.current).sort((a, b) => a.lotNumber > b.lotNumber ? 1 : -1).reverse();
      }
    });

    return { priceList, fields };
  },
};
</script>

<style>
.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #ffffff;
}
</style>
