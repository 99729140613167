import { createStore } from "vuex";

export default createStore({
  state: {
    apiToken: null,
  },
  mutations: {
    setApiToken(state, token) {
      state.apiToken = token;
    },
  },
  actions: {},
  modules: {},
});
