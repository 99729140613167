<template>
  <div class="top-sires">
    <widget heading="Top Sires">
      <bsa-table :lots="getTopSires" :fields="fields"></bsa-table>
    </widget>
  </div>
</template>

<script>
import BsaTable from "@/components/BsaTable.vue";

export default {
  props: ["sires"],
  components: {
    BsaTable,
  },
  data() {
    return {
      fields: [
        {
          name: "sire",
          width: "38%",
        },
        {
          name: "lotsSold",
          width: "10%",
          title: "Lots",
        },
        {
          name: "aggregate",
          title: "Aggregate",
          width: "26%",
          formatOutput(value) {
            return new Intl.NumberFormat().format(value);
          },
        },
        {
          name: "average",
          title: "Average",
          width: "26%",
          formatOutput(value) {
            return new Intl.NumberFormat("en", {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }).format(value);
          },
        },
      ],
    };
  },
  computed: {
    getTopSires() {
      if (this.sires.length) {
        return this.sires.filter((item) => item.lotsSold > 0).slice(0, 10);
      }
    },
  },
};
</script>

<style></style>
