<template>
  <div class="latest-prices">
    <widget heading="Latest Prices">
      <bsa-table v-if="latest" :lots="latest" :fields="fields"></bsa-table>
      <a
        v-if="latest.length > 4"
        @click="$emit('openPriceList')"
        class="btn mt-2 d-block"
        >View All</a
      >
    </widget>
  </div>
</template>

<script>
import BsaTable from "@/components/BsaTable.vue";
export default {
  data() {
    return {
      fields: [
        {
          name: "lotNumber",
          title: "Lot",
          width: "10%",
        },
        {
          name: "name",
          width: "48%",
          title: "Horse",
        },
        {
          name: "soldPrice",
          title: "Price",
          width: "30%",
          formatOutput(value, lot) {
            if (lot.type == "WITHDRAWN") {
              return "W/D";
            } else {
              return new Intl.NumberFormat().format(value);
            }
          },
        },
        {
          name: "type",
          title: " ",
          width: "2%",
          formatOutput(value) {
            return value == "SALE"
              ? '<i class="bi bi-check2"></i>'
              : '<i class="bi bi-x"></i>';
          },
        },
      ],
    };
  },
  components: {
    BsaTable,
  },
  inject: ["livedata"],
  computed: {
    latest() { 
      return this.livedata.value
        .filter(function(el) {
          return !el.current;
        })
        .sort((a, b) => a.lotNumber > b.lotNumber ? 1 : -1)
        .reverse()
        .slice(0, 5);
    },
  },
};
</script>

<style></style>
