<template>
  <div class="current-lot">
    <widget heading="Current Lot">
      <div class="d-flex current-lot-line">
        <div class="col-5 data-label">Lot number</div>
        <div v-if="currentLot" class="col-7 data-value">
          {{ currentLot.number }}
        </div>
        <div v-else>-</div>
      </div>
      <div class="d-flex current-lot-line">
        <div class="col-5 data-label">Name</div>
        <div v-if="currentLot" class="col-7 data-value">
          {{ currentLot.name }}
        </div>
        <div v-else>-</div>
      </div>
      <div class="d-flex current-lot-line">
        <div class="col-5 data-label">Sire</div>
        <div v-if="currentLot" class="col-7 data-value">
          {{ currentLot.sire }}
        </div>
        <div v-else>-</div>
      </div>
      <div class="d-flex current-lot-line">
        <div class="col-5 data-label">Dam</div>
        <div v-if="currentLot" class="col-7 data-value">
          {{ currentLot.dam }}
        </div>
        <div v-else>-</div>
      </div>
      <div class="d-flex current-lot-line">
        <div class="col-5 data-label">Vendor</div>
        <div v-if="currentLot" class="col-7 data-value">
          {{ currentLot.vendor }}
        </div>
        <div v-else>-</div>
      </div>
    </widget>
  </div>
</template>

<script>
export default {
  props: ["currentLot"],
};
</script>

<style>
.current-lot-line > div {
  padding: 5px 0;
}
</style>
