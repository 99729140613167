<template>
  <div class="current-lot">
    <widget heading="Progressive Counts">
      <div class="d-flex current-lot-line">
        <div class="col-5 data-label">Lots catalogued</div>
        <div v-if="getStats.catGd" class="col-7 data-value">
          {{ getStats.catGd }}
        </div>
        <div v-else>-</div>
      </div>
      <div class="d-flex current-lot-line">
        <div class="col-5 data-label">Sold</div>
        <div v-if="getStats.lotsSold" class="col-7 data-value">
          {{ getStats.lotsSold }}
        </div>
        <div v-else>-</div>
      </div>
      <div class="d-flex current-lot-line">
        <div class="col-5 data-label">Withdrawn</div>
        <div v-if="getStats.withdrawn" class="col-7 data-value">
          {{ getStats.withdrawn }}
        </div>
        <div v-else>-</div>
      </div>
      <div class="d-flex current-lot-line">
        <div class="col-5 data-label">Not Sold</div>
        <div v-if="getStats.notSold" class="col-7 data-value">
          {{ getStats.notSold }}
        </div>
        <div v-else>-</div>
      </div>
    </widget>
  </div>
</template>

<script>
export default {
  inject: ["livedata"],
  computed: {
    getStats() {
      let sold = 0;
      let withd = 0;
      let notsold = 0;
      let cat = 0;

      this.livedata.value.forEach((element) => {
        element.type == "SALE" ? sold++ : false;
        element.type == "WITHDRAWN" ? withd++ : false;
        element.type == "NO_SALE" ? notsold++ : false;
        !element.current ? cat++ : false;
      });
      return {
        lotsSold: sold,
        withdrawn: withd,
        notSold: notsold,
        catGd: cat,
      };
    },
  },
};
</script>

<style>
.current-lot-line > div {
  padding: 5px 0;
}
</style>
