import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Admin from '../views/Admin.vue'
import Login from '../views/auth/Login.vue'
import { AppAuth } from '../firebase/config';


const requireAuth = (to,from,next) => {
  let user = AppAuth.currentUser
  if(!user) {
    next({name: 'Login'})
  }
  else{
    next()
  }
}

const requireNoAuth = (to, from, next) =>{
  let user = AppAuth.currentUser
  if(user) {
    next({name: 'Admin'})
  }
  else{
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    beforeEnter: requireAuth,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: requireNoAuth
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
