import { ref } from 'vue';
import { AppAuth } from '../firebase/config';

// refs & signup outside of exported function
// they don't need to be re-created every time we invoke useSignup
const error = ref(null);

const logout = async () => {
    error.value = null

    try{
        await AppAuth.signOut()
    } catch(err){
        console.log(err.message)
        error.value = err.message
    } 
}

const useLogout = () => {
    return {logout, error}
}

export default useLogout