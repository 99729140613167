import { ref, watchEffect } from 'vue'
import { AppFirestore } from '../firebase/config'

const getCollection = (collection) => {

  const documents = ref([])
  const error = ref(null)

  // register the firestore collection reference
  let collectionRef = null

  if(collection == 'video' || collection == 'event'){
      collectionRef = AppFirestore.collection(collection)
  }
  else if(collection == 'sires'){
    collectionRef = AppFirestore.collection(collection).orderBy('aggregate', 'desc')
  } else {
    collectionRef = AppFirestore.collection(collection).orderBy('timestamp', 'desc')
  }


  const unsub = collectionRef.onSnapshot(snap => {
      let results = []
  
    snap.docs.forEach(doc => {
  
      if(doc.id === 'current'){
        results.push({id: doc.get('id'), current: true})
      }else{
        results.push({...doc.data(), id: doc.id})
      }
    });
    
    // update values
    documents.value = results
    error.value = null
  }, err => {
    documents.value = null
    err.value = 'could not fetch the data'
  })

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub())
  })

  return { error, documents }
}

export default getCollection