<template>
  <div class="current-lot">
    <widget heading="Progressive Stats">
      <div class="d-flex current-lot-line">
        <div class="col-5 data-label">Lots catalogued</div>
        <div v-if="getStats.catGd.length" class="col-7 data-value">
          {{ getStats.catGd.length }}
        </div>
        <div v-else>-</div>
      </div>
      <div class="d-flex current-lot-line">
        <div class="col-5 data-label">Aggregate</div>
        <div v-if="getStats.agg" class="col-7 data-value">
          {{ getStats.agg }}
        </div>
        <div v-else>-</div>
      </div>
      <div class="d-flex current-lot-line">
        <div class="col-5 data-label">Average</div>
        <div v-if="getStats.avg" class="col-7 data-value">
          {{ getStats.avg }}
        </div>
        <div v-else>-</div>
      </div>
      <div class="d-flex current-lot-line">
        <div class="col-5 data-label">Median</div>
        <div v-if="getStats.median" class="col-7 data-value">
          {{ getStats.median }}
        </div>
        <div v-else>-</div>
      </div>
    </widget>
  </div>
</template>

<script>
export default {
  // props: {
  //     lots: Array,
  //     lot: Object
  // }
  inject: ["livedata"],
  computed: {
    getStats() {
      const lotsSold = () => {
        return this.livedata.value.filter(function(el) {
          return el.type == "SALE";
        });
      };

      const catGd = () => {
        return this.livedata.value.filter(function(el) {
          return !el.current;
        });
      };

      const getAgg = (x) => {
        return x.reduce(
          (acc, value) =>
            //   console.log(value.soldPrice);
            acc + value.soldPrice,
          0
        );
      };
      const getAvg = () => {
        return getAgg(lotsSold()) / lotsSold().length;
      };

      const median = (values) => {
        if (values.length === 0) return 0;

        values.sort(function(a, b) {
          return parseFloat(a.soldPrice) - parseFloat(b.soldPrice);
        });

        var half = Math.floor(values.length / 2);

        if (values.length % 2) return values[half].soldPrice;

        return (values[half - 1].soldPrice + values[half].soldPrice) / 2.0;
      };

      return {
        agg: new Intl.NumberFormat().format(getAgg(lotsSold())),
        avg: new Intl.NumberFormat("en", {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }).format(getAvg()),
        median: new Intl.NumberFormat("en", {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }).format(median(lotsSold())),
        catGd: catGd(),
      };
    },
  },
  methods: {},
};
</script>

<style>
.current-lot-line > div {
  padding: 5px 0;
}
</style>
