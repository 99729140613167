import { ref } from 'vue';
import { AppAuth } from '../firebase/config';

const user = ref(AppAuth.currentUser)

AppAuth.onAuthStateChanged(_user => {
    user.value = _user
})

const getUser = () => {
    return {user}
}

export default getUser