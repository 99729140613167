<template>
  <div class="login">
    <div class="container">
      <div class="form-container">
        <form @submit.prevent="handleLogin">
          <h2>Admin Login</h2>
          <div>
            <div class="d-flex flex-column mb-3">
              <label class="mb-1" for="">Email</label>
              <input type="email" placeholder="Email" v-model="email" />
            </div>
            <div class="d-flex flex-column mb-3">
              <label class="mb-1" for="">Password</label>
              <input
                type="password"
                placeholder="Password"
                v-model="password"
              />
            </div>
            <button class="btn" v-if="!isPending">Login</button>
            <button class="btn" v-if="isPending" disabled>Loading...</button>

            <div v-if="error">{{ error }}</div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import useLogin from "@/composables/useLogin";
import { useRouter } from "vue-router";

export default {
  setup() {
    const { error, login, isPending } = useLogin();

    const router = useRouter();

    const email = ref("");
    const password = ref("");

    const handleLogin = async () => {
      const res = await login(email.value, password.value);
      if (!error.value) {
        // console.log('user Logged in');
        router.push({ name: "Admin" });
      }
    };
    return { handleLogin, email, password, error, isPending };
  },
};
</script>

<style>
.form-container {
  padding: 40px;
  max-width: 600px;
  margin: 60px auto;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 61%);
  background-color: #222222;
}
.form-container h2 {
  margin-top: 0;
}
</style>
