import { ref } from "vue";
import { AppFirestore } from "../firebase/config";

const getLiveEvent = () => {
  const eventError = ref(null);

  const event = ref(null);

  const loadEvent = async () =>
    await AppFirestore.collection("event")
      .doc("current")
      .get()
      .then((doc) => {
        if (doc.exists) {
          // console.log("Document data:", doc.data());
          event.value = doc.data();
        } else {
          // doc.data() will be undefined in this case
          throw Error("There is no live event at the moment");
        }
      })
      .catch((error) => {
        eventError.value = error.message;
        // console.log("Error getting document:", error.message);
      });

  //   const event = await collref.get().then((doc) => {
  //     if (doc.exists) {
  //         console.log("Document data:", doc.data());
  //         return doc.data();
  //     } else {
  //         // doc.data() will be undefined in this case
  //         throw Error('No such document!')
  //     }
  //     }).catch((error) => {
  //         eventError = error.message
  //         // console.log("Error getting document:", error);
  //     });

  return { event, eventError, loadEvent };
};

export default getLiveEvent;
