<template>
  <div class="home">
    <div>
      <h1 v-if="event">{{ event.title }} - {{ event.year }}</h1>
      <div>
        <resources @openPopup="$emit('openPopup')" class="mb-4"></resources>
      </div>
      <div class="row">
        <div class="col-lg-8">
          <div class="row">
            <div class="col-12">
              <video-frame> </video-frame>
            </div>
            <div class="col-12 mt-4 mb-4">
              <lot-list :lots="wpdata" :currentLot="currentLot"></lot-list>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <current-lot :currentLot="currentLot" class="mb-4"></current-lot>
          <stats class="mb-4" v-if="documents.length" :docs="documents"></stats>
          <latest-prices
            @openPriceList="$emit('openPriceList')"
            class="mb-4"
          ></latest-prices>
          <top-lots class="mb-4"></top-lots>
          <top-sires v-if="documents.length" :sires="documents"></top-sires>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Resources from "@/components/Resources.vue";
import VideoFrame from "@/components/VideoFrame.vue";
import CurrentLot from "@/components/CurrentLot.vue";
import Stats from "@/components/Stats.vue";
import LatestPrices from "@/components/LatestPrices.vue";
import TopLots from "@/components/TopLots.vue";
import TopSires from "@/components/TopSires.vue";
import LotList from "@/components/LotList.vue";
import getCollection from "@/composables/getCollection";
import { inject, computed } from "vue";
export default {
  name: "Home",
  components: {
    CurrentLot,
    VideoFrame,
    Resources,
    LatestPrices,
    TopLots,
    TopSires,
    LotList,
    Stats,
  },
  props: ["event"],
  setup(props) {
    const { error, documents } = getCollection("sires");
    const wpdata = inject("wpdata");
    const livedata = inject("livedata");

    const currentLot = computed(() => {
      if (livedata.value.length) {
        return wpdata.value.find(
          (obj) => obj.id === livedata.value.find((item) => item.current)?.id
        );
      }
    });

    return { error, documents, currentLot, wpdata };
  },
};
</script>
