import { ref } from "vue";

const getWPEvents = () => {
  const wperror = ref(null);
  const data = ref(null);

  const wpload = async () => {
    try {
      // let data = await axios.get('http://localhost:3000/event')
      let res = await fetch(
        "https://bsa.co.za/wp-json/wp_event_lots/v1/all-events"
      );
      if (!res.ok) {
        throw Error("The Data is not available at the moment");
      }
      data.value = await res.json();
      // event.value = data.value.event
    } catch (err) {
      wperror.value = err.message;
    }
  };

  return { data, wperror, wpload };
};

export default getWPEvents;
