<template>
  <div class="top-lots">
    <widget heading="Top Lots">
      <bsa-table v-if="top" :lots="top" :fields="fields"></bsa-table>
    </widget>
  </div>
</template>

<script>
import BsaTable from "@/components/BsaTable.vue";
export default {
  props: {
    lots: Array,
  },
  inject: ["livedata"],
  components: {
    BsaTable,
  },
  data() {
    return {
      fields: [
        {
          name: "lotNumber",
          title: "Lot",
          width: "10%",
        },
        {
          name: "name",
          width: "60%",
          title: "Horse",
        },
        {
          name: "soldPrice",
          title: "Price",
          width: "30%",
          formatOutput(value) {
            return new Intl.NumberFormat().format(value);
          },
        },
      ],
    };
  },
  computed: {
    top() {
      let newArray = this.livedata.value.filter(function(el) {
        return el.type == "SALE";
      });
      return newArray.sort((a, b) => b.soldPrice - a.soldPrice).slice(0, 10);
    },
  },
};
</script>

<style></style>
