<template>
  <div class="widget">
    <div class="widget-header">
      <h3 v-html="heading"></h3>
    </div>
    <div class="widget-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Widget',
  props: {
    heading: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.widget-header{
  background-color: #222222;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 61%);
  padding: 10px 20px;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.widget-header h3{
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #C19E78;
  line-height: 1.4;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.widget-body{
  background-color: #222222;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 61%);
  padding: 15px 20px;
  position: relative;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
