<template>
  <div class="resources">
    <ul>
      <li>
        <a class="btn" :href="eventURL" target="_blank">Price List</a>
      </li>
      <li>
        <a class="btn" @click.prevent="$emit('openPopup')" href=""
          >Passed through</a
        >
      </li>
      <li>
        <a class="btn" @click="downloadPriceList">Price List printable</a>
      </li>
      <li v-show="admin">
        <router-link class="btn" to="/">WEBCAST PAGE</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import downloadCSV from "@/composables/downloadCSV";
export default {
  inject: ["livedata", "event"],
  props: ["admin"],
  computed: {
    eventURL() {
      // console.log(this.event);
      if (this.event.value) {
        return this.event.value.eventUrl;
      }
    },
  },
  methods: {
    downloadPriceList() {
      let $formated = this.formatLots(
        this.livedata.value.filter(
          (item) => item.type != "WITHDRAWN" && !item.current
        )
      );

      let $headers = {
        lot: "lot", // remove commas to avoid errors,
        name: "name",
        sire: "sire",
        dam: "dam",
        vendor: "vendor",
        price: "price",
        buyer: "buyer",
        reserve: "reserve",
      };
      downloadCSV(
        $headers,
        $formated,
        this.event.value.title
          ? this.event.value.title + " - " + this.event.value.year
          : "BSA LIVE SALES - PRICE LIST"
      );
    },
    formatLots(x) {
      let formated = [];
      x.forEach((item) => {
        formated.push({
          lot: item.lotNumber, // remove commas to avoid errors,
          name: item.name,
          sire: item.sire,
          dam: item.dam,
          vendor: item.vendor,
          price: new Intl.NumberFormat()
            .format(item.soldPrice)
            .replace(/,/g, " "),
          buyer: item.buyer,
          reserve: new Intl.NumberFormat()
            .format(item.reservePrice)
            .replace(/,/g, " "),
        });
      });
      return formated;
    },
  },
};
</script>

<style scoped>
.resources li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
