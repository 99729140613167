<template>
  <div class="lots-catalogue">
    <div class="catalogue-info">
      Click on lot number or name to see more details about the lot
    </div>
    <div class="cat-table-responsive">
      <div class="bsa-table-cat-wrap">
        <bsa-table
          v-if="lots"
          :current="currentLot"
          :lots="lots"
          :fields="fields"
          :fixed="true"
        ></bsa-table>
      </div>
    </div>
  </div>
</template>

<script>
import BsaTable from "@/components/BsaTable.vue";
export default {
  props: {
    lots: Object,
    currentLot: Object,
  },
  components: {
    BsaTable,
  },
  data() {
    return {
      fields: [
        {
          name: "number",
          title: "lot",
          width: "6%",
          formatOutput(value, lot) {
            if (lot.url) {
              return (
                '<a target="_blank" href="' + lot.url + '">' + value + "</a>"
              );
            }
            return value;
          },
        },
        {
          name: "name",
          width: "22%",
          formatOutput(value, lot) {
            if (lot.url) {
              return (
                '<a target="_blank" href="' + lot.url + '">' + value + "</a>"
              );
            }
            return value;
          },
        },
        {
          name: "sex",
          width: "6%",
        },
        {
          name: "sire",
          width: "22%",
        },
        {
          name: "dam",
          width: "22%",
        },
        {
          name: "vendor",
          width: "22%",
        },
      ],
    };
  },
};
</script>

<style></style>
