<template>
  <div class="admin-sidebar">
    <div class="sidebar-header">
      <a @click="$emit('closeMe')"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="#C19E78"
          class="bi bi-x-circle-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"
          />
        </svg>
      </a>
    </div>
    <div class="sidebar-body">
      <widget class="mb-4" heading="Set Live Event">
        <div class="d-flex flex-column">
          <select v-if="events" v-model="selectedEvent">
            <option disabled>Please select one</option>
            <option v-for="event in events" :key="event.id" :value="event.id">{{
              event.title
            }}</option>
          </select>
          <a class="btn mt-2" @click="setActiveEvent">
            Submit
          </a>
        </div>
        <div class="error" v-if="yerror">{{ yerror[0] }}</div>
      </widget>
      <widget class="mb-4" heading="Youtube Link">
        <div class="d-flex flex-column">
          <input
            class="video-input flex-fill"
            placeholder="Insert Youtube Link Here"
            type="text"
            v-model="ytLink"
          />
          <a class="btn mt-2" @click="setYoutubeVideo">
            Submit
          </a>
        </div>
        <div class="error" v-if="yerror">{{ yerror[0] }}</div>
      </widget>

      <widget heading="REMOVE LIVE EVENT?">
        <a class="btn btn-danger d-block" @click="deleteOption = true"
          >DELETE EVENT DATA</a
        >
      </widget>
    </div>
    <div class="delete-wrapper" v-show="deleteOption">
      <h3 style="color: #f03f50">
        This is action is irreversible
      </h3>
      <h2>
        Are you sure you want to delete live event's data from App?
      </h2>
      <a class="btn d-block btn-danger mb-2" @click="deleteAllData"
        >YES, I AM SURE</a
      >
      <a class="btn d-block" @click="deleteOption = false">CLOSE</a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import getUser from "../composables/getUser";
import getWPEvents from "@/composables/getWPEvents";
import base64url from "base64url";
import { ref, inject } from "vue";
export default {
  watch: {
    // whenever question changes, this function will run
    currentEvent(newValue, oldValue) {
      if(newValue) {
        this.selectedEvent = newValue.id;
      }
    }
  },
  setup(_, { emit }) {
    const { user } = getUser();
    const ytLink = ref("");
    const yerror = ref("");
    const events = ref([]);
    const currentEvent = inject("event");
    const selectedEvent = ref("");
    const deleteOption = ref(false);

    const { data, wperror, wpload } = getWPEvents();

    wpload().then(() => {
      events.value = data.value.data.events;
    });

    const setYoutubeVideo = () => {
      user.value.getIdToken().then((token) => {
        axios
          .put(
            "https://bsa-live-sales-api-gateway-5mbe41bd.nw.gateway.dev/v1/video/stream",
            null,
            {
              params: { url: base64url.encode(ytLink.value) },
              headers: { Authorization: "Bearer " + token },
            }
          )
          .then((r) => {
            // console.log(r);
          })
          .catch((err) => {
            // console.log(err)
            yerror.value = err.response.data.message;
          });
      });
    };

    const deleteAllData = () => {
      user.value.getIdToken().then((token) => {
        axios
          .delete(
            "https://bsa-live-sales-api-gateway-5mbe41bd.nw.gateway.dev/v1/data",
            {
              headers: { Authorization: "Bearer " + token },
            }
          )
          .then((r) => {
            // console.log(r);
          })
          .catch((err) => {
            // console.log(err)
            console.log(err.response.data.message);
          });
      });
    };

    const formatDate = (d) => {
      let date = new Date(d);
      return date
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, "");
    };

    const setActiveEvent = () => {
      let foundEvent = events.value.find(event => event.id == selectedEvent.value);
      let args = {
        id: parseInt(foundEvent.id),
        title: foundEvent.title,
        date: foundEvent.start_date,
      };
      // console.log(args);
      user.value.getIdToken().then((token) => {
        axios
          .put(
            "https://bsa-live-sales-api-gateway-5mbe41bd.nw.gateway.dev/v1/event",
            args,
            {
              headers: { Authorization: "Bearer " + token },
            }
          )
          .then((r) => {
            if (r.status == 200) {
              emit("setevent");
            }
          })
          .catch((err) => {
            // console.log(err.response.data.message);
            // (yerror.value = err.response.data.message)
          });
      });
    };

    return {
      ytLink,
      yerror,
      setYoutubeVideo,
      events,
      selectedEvent,
      setActiveEvent,
      deleteOption,
      deleteAllData,
      currentEvent
    };
  },
};
</script>

<style scoped>
.admin-sidebar {
  background-color: #222222;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 61%);
  position: fixed;
  top: 0;
  right: 0;
  width: 360px;
  max-width: 100%;
  bottom: 0;
  z-index: 999;
  box-sizing: border-box;
}
.sidebar-header,
.sidebar-body {
  padding: 20px;
}
.sidebar-header {
  border-bottom: 1px solid #c19e78;
}
.delete-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #222222;
  z-index: 99;
  padding: 20px;
}
</style>
