import { ref } from "vue";
import { AppAuth } from "../firebase/config";

// refs & signup outside of exported function
// they don't need to be re-created every time we invoke useSignup
const error = ref(null);
const isPending = ref(false);

const login = async (email, password) => {
  error.value = null;
  isPending.value = true;

  try {
    const res = await AppAuth.signInWithEmailAndPassword(email, password);
    error.value = null;
    isPending.value = false;
    // console.log(AppAuth.currentUser)
    return res;
  } catch (err) {
    isPending.value = false;
    error.value = "Incorrect login credentials";
  }
};

const useLogin = () => {
  return { error, login, isPending };
};

export default useLogin;
