<template>
  <div class="bsa-table">
    <div v-if="fixed" class="bsa-table-fixed-header">
      <div class="d-flex">
        <div
          :style="{ width: field.width }"
          v-for="field in fields"
          :key="field"
        >
          <span v-html="field.title ? field.title : field.name"></span>
        </div>
      </div>
    </div>
    <div ref="tablescroll" :class="fixed ? 'fixed-head' : ''">
      <table cellspacing="0">
        <tr v-if="!fixed">
          <th :width="field.width" v-for="field in fields" :key="field">
            <span v-html="field.title ? field.title : field.name"></span>
          </th>
        </tr>
        <tr ref="kaka" class="table-gap">
          <td :colspan="fields.length"></td>
        </tr>
        <tr
          :class="[
            getCurrentId == lot.id ? 'current-lot' : '',
            lot.type ? 'status' + lot.type : '',
          ]"
          v-for="(lot, index) in lots"
          :key="index"
          :ref="'lot' + lot.number"
        >
          <td :width="field.width" v-for="field in fields" :key="field.name">
            <span v-html="renderField(lot, field)"> </span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lots: Array,
    fields: Array,
    fixed: Boolean,
    current: Object,
  },
  methods: {
    renderField(lot, field) {
      if (typeof field.formatOutput !== "undefined") {
        return field.formatOutput(lot[field.name], lot);
      } else {
        return lot[field.name];
      }
    },
  },
  computed: {
    getCurrentId() {
      if (this.current) {
        return this.current.id;
      }
      return 0;
    },
  },
  watch: {
    current(newLot, oldLot) {
      //   console.log(newLot.number);
      if (this.$refs["lot" + newLot.number]) {
        // console.log(this.$refs["lot" + newLot.number]);
        // this.$refs["lot" + newLot.number].scrollIntoView(true);
        this.$refs.tablescroll.scrollTop = this.$refs[
          "lot" + newLot.number
        ].offsetTop;
      }
    },
  },
};
</script>

<style>
.bsa-table table {
  width: 100%;
  text-align: left;
  font-size: 12px;
}
.bsa-table table th,
.bsa-table table td {
  padding: 8px 10px;
}
.bsa-table table th {
  border-right: 3px solid #c19e78;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  color: #c19e78;
}
.bsa-table table th:last-child {
  border-right: 0;
}
.bsa-table table td {
  border-top: 1px solid #c19e78;
}
.bsa-table table th:first-child,
.bsa-table table td:first-child {
  padding-left: 0;
}
.bsa-table table th:last-child,
.bsa-table table td:last-child {
  padding-right: 0;
}
.bsa-table table .table-gap td {
  border-top: 0;
}
.bsa-table a {
  color: inherit;
  text-decoration: none;
}
.bsa-table .fixed-head {
  height: 650px;
  position: relative;
  overflow-y: auto;
  scrollbar-width: thin;
  padding: 10px;
  box-shadow: 0 0 6px #000000;
  background-color: #222222;
}
.bsa-table-fixed-header {
  padding: 10px;
  box-shadow: 0 0 6px #000000;
  background-color: #222222;
  text-transform: uppercase;
  font-size: 12px;
}
</style>
