<template>
  <header class="header">
    <div class="header-top">
      <div class="container">
        <div class="d-flex align-items-center">
          <div>
            <a href="https://bsa.co.za/">
              <img src="@/assets/images/logo.png" width="180" alt="" />
            </a>
          </div>
          <div class="ms-auto">
            <div class="menu-toggle-wrapper">
              <a class="d-block" @click="mobmenu = !mobmenu">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  class="bi bi-list"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-bottom" :class="mobmenu ? 'menu-active' : ''">
      <div class="container">
        <nav>
          <div v-show="mobmenu" class="py-3">
            <a @click="mobmenu = false">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path
                  d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"
                />
              </svg>
            </a>
          </div>
          <ul class="main-menu">
            <li><a href="https://bsa.co.za/" target="_blank">HOME</a></li>
            <li><a href="https://www.tba.co.za/" target="_blank">TBA</a></li>
            <li>
              <a class="has-dropdown d-flex align-items-center" target="_blank"
                >SALES &amp; RESULTS
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-chevron-down ms-1"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  /></svg
              ></a>
              <ul class="dropdown-menu">
                <li>
                  <a href="https://bsa.co.za/events/" target="_blank"
                    >Past Sales</a
                  >
                </li>
                <li>
                  <a href="https://bsa.co.za/calendar/" target="_blank"
                    >Sales Calendar</a
                  >
                </li>
                <li>
                  <a href="https://bsa.co.za/sales-documents/" target="_blank"
                    >Sales Documents</a
                  >
                </li>
              </ul>
            </li>
            <li>
              <a href="https://bsa.co.za/entry-forms/" target="_blank"
                >ENTRY FORMS</a
              >
            </li>
            <li>
              <a href="https://bsa.co.za/order-catalogue/" target="_blank"
                >Order catalogue</a
              >
            </li>
            <li>
              <a href="https://www.equineonlinesales.co.za/" target="_blank"
                >Online sales</a
              >
            </li>

            <li><a href="https://bsa.co.za/news/" target="_blank">NEWS</a></li>
            <li>
              <a href="https://bsa.co.za/contact/" target="_blank">CONTACt</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  data() {
    return {
      mobmenu: false,
    };
  },
};
</script>
<style>
.header-top {
  box-shadow: 0px 3px 10px rgb(0 0 0 / 61%);
  background-color: #222222;
  padding: 10px 0 10px 0;
  position: relative;
  z-index: 2;
}
.header-bottom {
  box-shadow: 0px 3px 10px rgb(0 0 0 / 61%);
  background-color: #222222;
  position: relative;
  z-index: 1;
}
nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
nav ul li {
  display: inline-block;
}
nav ul.main-menu > li {
  position: relative;
}
.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  z-index: 100;
  background-color: #222222;
  width: 200px;
  border-top: 2px solid #c19e78;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 61%);
}
.dropdown-menu li {
  display: block;
  border-bottom: 1px solid #c19e78;
}
.dropdown-menu li:last-child {
  border-bottom: 0;
}
.dropdown-menu li a {
  line-height: 40px;
}
.dropdown-menu li a:hover {
  background-color: #c19e78;
  color: #222222;
}
.has-dropdown:hover + .dropdown-menu {
  display: block;
}
.dropdown-menu:hover {
  display: block;
}
nav ul li a {
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0 12px !important;
  line-height: 60px;
  letter-spacing: 1px;
  display: block;
  color: #ffffff;
  text-decoration: none;
}
</style>
