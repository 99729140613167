<template>
  <div class="video-frame">
      <div class="video-frame-header">
           <h3>Live video feed</h3>
      </div>
      <div class="video-container">
          <iframe v-if="documents.length" class="responsive-iframe" width="560" height="315" :src="documents[0].url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>

  </div>
</template>

<script>
import getCollection from '@/composables/getCollection'
export default {
    // props: {
    //     heading: String,
    //     videoid: String    
    // }
    setup(){
      const { error, documents} = getCollection('video')

      return { error, documents}
    }
}
</script>

<style>
    .video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.video-frame{
    border: 1px solid #C19E78;
}
.video-frame-header{
    background-color: #222222;
    border-bottom: 1px solid #C19E78;
    padding: 10px 20px;
    position: relative;
    z-index: 1;
}

.video-frame-header h3{
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #C19E78;
  line-height: 1.4;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}

</style>