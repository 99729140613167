<template>
  <div class="admin">
    <admin-sidebar
      @setevent="$emit('setevent')"
      @closeMe="sidebarOpen = false"
      v-show="sidebarOpen"
    ></admin-sidebar>
    <div>
      <div class="event-title row align-items-center flex-md-row-reverse">
        <div class="col-md-3 d-flex justify-content-end align-items-center">
          <a class="btn" @click="logOutUser">Logout</a>
          <a
            class="ms-3 d-inline-block sidebar-toggle"
            @click="sidebarOpen = !sidebarOpen"
          >
            <img
              width="24"
              height="24"
              src="../assets/images/settings-icon.svg"
              alt=""
            />
          </a>
        </div>
        <div class="col-md-9">
          <h1 v-if="event">{{ event.title }} - {{ event.year }}</h1>
        </div>
      </div>
      <div>
        <resources
          admin="true"
          @openPopup="$emit('openPopup')"
          class="mb-4"
        ></resources>
      </div>
      <!-- <div>{{error}}</div> -->
      <div class="row">
        <div class="col-lg-8">
          <div class="row flex-lg-row-reverse">
            <div class="col-lg-7">
              <widget class="mb-4" heading="Update Current Lot">
                <div class="set-current-wrapper d-flex align-items-center">
                  <label
                    class="set-current-lot-label"
                    style="margin-right: 20px"
                    for=""
                    >Current Lot</label
                  >
                  <input
                    class="current-lot-input"
                    type="text"
                    v-model="cLotId"
                  />
                  <a
                    class="btn"
                    style="margin-left: auto"
                    @click="setCurrentLot"
                  >
                    submit
                  </a>
                </div>
                <div class="error" v-if="clerror">
                  <a class="close-error" @click="clerror = false"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-x-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                      />
                      <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                      /></svg></a
                  >{{ clerror }}
                </div>
              </widget>
              <widget
                class="mb-4 create-transaction"
                heading="Create Transaction"
              >
                <div class="row flex-sm-row-reverse">
                  <div class="col-sm-4 text-right">
                    <a class="btn mb-2" @click="recallLot">
                      Recall
                    </a>
                  </div>
                  <div class="col-sm-8">
                    <!-- Lot Number -->
                    <div class="row align-items-center mb-2">
                      <div class="col-5">
                        <label for="">Lot Number</label>
                      </div>
                      <div class="col-7">
                        <input type="text" v-model="lotNum" />
                      </div>
                    </div>
                    <!-- Type -->
                    <div class="row align-items-center mb-2">
                      <div class="col-5">
                        <label for="">Type</label>
                      </div>
                      <div class="col-7">
                        <select @change="typeChanged" v-model="type">
                          <option value="SALE">Sale</option>
                          <option value="NO_SALE">No Sale</option>
                          <option value="WITHDRAWN">Withdrawn</option>
                        </select>
                      </div>
                    </div>
                    <div class="row align-items-center mb-2">
                      <div class="col-5">
                        <label for="">Price Sold</label>
                      </div>
                      <div class="col-7">
                        <input type="text" v-model="priceSold" />
                      </div>
                    </div>
                    <div class="row align-items-center mb-2">
                      <div class="col-5">
                        <label for="">Buyer</label>
                      </div>
                      <div class="col-7 buyers-list-wrap">
                        <input
                          type="text"
                          v-model="buyer"
                          @focus="buyerFocus = true"
                          @blur="clearBuyerResults()"
                        />
                        <div
                          class="buyers-list"
                          v-show="buyerFocus && previousBuyers.length"
                        >
                          <ul>
                            <li
                              v-for="buyerName in previousBuyers"
                              :key="buyerName"
                            >
                              <a @click="buyer = buyerName">{{ buyerName }}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col-5">
                        <label for="">Reserve Price</label>
                      </div>
                      <div class="col-7">
                        <input type="text" v-model="reservePrice" />
                      </div>
                    </div>
                    <div class="mt-4">
                      <a class="btn" @click="createTrans">
                        <span v-if="recall">Update</span>
                        <span v-else>Submit</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="error" v-if="terror">
                  <a class="close-error" @click="terror = false"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-x-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                      />
                      <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                      /></svg></a
                  >{{ terror }}
                </div>
              </widget>
              <counts></counts>
            </div>
            <div class="col-lg-5">
              <current-lot :currentLot="currentLot" class="mb-4"></current-lot>
              <!-- <div v-if="currentLot">{{currentLot}}</div> -->
              <latest-prices
                @openPriceList="$emit('openPriceList')"
                class="mb-4"
              ></latest-prices>

              <stats v-if="livedata.length" :docs="livedata"></stats>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <top-lots class="mb-4"></top-lots>
          <top-sires :sires="documents"></top-sires>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CurrentLot from "@/components/CurrentLot.vue";
import AdminSidebar from "@/components/AdminSidebar.vue";
import getCollection from "@/composables/getCollection";
import getUser from "../composables/getUser";
import Resources from "@/components/Resources.vue";
import useLogout from "../composables/useLogout";
import Stats from "@/components/Stats.vue";
import Counts from "@/components/Counts.vue";
import axios from "axios";
import { useRouter } from "vue-router";
import LatestPrices from "@/components/LatestPrices.vue";
import TopLots from "@/components/TopLots.vue";
import TopSires from "@/components/TopSires.vue";
import { computed, ref, inject, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "Admin",
  components: {
    CurrentLot,
    LatestPrices,
    TopLots,
    TopSires,
    AdminSidebar,
    Stats,
    Counts,
    Resources,
  },
  props: ["event"],
  // Composition API
  setup(props) {
    const store = useStore();
    const recallttamp = ref(null);
    const sidebarOpen = ref(false);
    const recall = ref(null);
    const terror = ref(null);
    const prepData = ref(null);
    const lotNum = ref("");
    const type = ref("SALE");
    const priceSold = ref("");
    const buyer = ref("");
    const buyerFocus = ref(false);
    const reservePrice = ref("-");
    const cLotId = ref("");
    const clerror = ref(null);

    const wpdata = inject("wpdata");
    const livedata = inject("livedata");
    /**
     * User Login/Logut
     */

    const { user } = getUser();
    const { logout } = useLogout();

    // Router to redirect to login if no auth
    const router = useRouter();

    const logOutUser = async () => {
      await logout();
      router.push({ name: "Login" });
    };
    /*** Login End */

    const typeChanged = () => {
      // console.log(type.value);
      if (type.value == "NO_SALE") {
        buyer.value = "NOT SOLD";
        priceSold.value = "0";
      }
      if (type.value == "WITHDRAWN") {
        priceSold.value = "0";
        buyer.value = "WITHDRAWN";
      }
    };

    const { error, documents } = getCollection("sires");

    // Set The current Lot
    const setCurrentLot = () => {
      if (cLotId.value) {
        const lotid = wpdata.value.find((obj) => obj.number === cLotId.value)
          ?.id;
        if (lotid) {
          clerror.value = null;
          user.value.getIdToken().then((token) => {
            axios
              .put(
                "https://bsa-live-sales-api-gateway-5mbe41bd.nw.gateway.dev/v1/lots/current",
                null,
                {
                  params: { id: lotid },
                  headers: { Authorization: "Bearer " + token },
                }
              )
              .then((r) => {
                //console.log(r.status);
              })
              .catch((err) => (clerror.value = err.response.data.message));
          });
        } else {
          clerror.value =
            "Lot Number " + cLotId.value + " not found on Catalogue";
        }
      } else {
        clerror.value = "Lot Number is required to set the currrent lot";
      }
    };

    const createTrans = () => {
      if (wpdata.value) {
        const wpobj = wpdata.value.find((obj) => obj.number == lotNum.value);

        if (wpobj) {
          prepData.value = {
            id: wpobj.id,
            lotNumber: parseInt(wpobj.number, 10),
            type: type.value,
            name: wpobj.name,
            sire: wpobj.sire,
            dam: wpobj.dam,
            vendor: wpobj.vendor,
            buyer: buyer.value,
            soldPrice: parseFloat(priceSold.value),
            reservePrice:
              reservePrice.value == "-" ? 0 : parseFloat(reservePrice.value),
          };

          if (!recall.value) {
            postLot();
          } else {
            prepData.value = {
              id: wpobj.id,
              lotNumber: parseInt(wpobj.number, 10),
              type: type.value,
              name: wpobj.name,
              sire: wpobj.sire,
              dam: wpobj.dam,
              vendor: wpobj.vendor,
              buyer: buyer.value,
              soldPrice: parseFloat(priceSold.value),
              reservePrice:
                reservePrice.value == "-" ? 0 : parseFloat(reservePrice.value),
              timestamp: recallttamp.value,
            };
            user.value
              .getIdToken()
              .then((token) =>
                axios.delete(
                  "https://bsa-live-sales-api-gateway-5mbe41bd.nw.gateway.dev/v1/lots",
                  {
                    params: { id: prepData.value.id },
                    headers: { Authorization: "Bearer " + token },
                  }
                )
              )
              .then(() => {
                axios.post(
                  "https://bsa.co.za/wp-json/wp_event_lots/v1/undo-lot-action",
                  {
                    id: prepData.value.id,
                    action:
                      recall.value == "NO_SALE"
                        ? "NOT_SALE"
                        : prepData.value.type,
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + store.state.apiToken,
                    },
                  }
                );
                postLot();
              });
          }
        } else {
          terror.value = `Lot ${lotNum.value} not found, if the lot was marked as withdrwan it will not be available for transactions.`;
          lotNum.value = "";
          type.value = "SALE";
          priceSold.value = "";
          buyer.value = "";
          reservePrice.value = "-";
          recall.value = null;
          recallttamp.value = null;
        }
      }
    };

    const postLot = () => {
      // Sent New Transaction to Firebase
      user.value.getIdToken().then((token) => {
        axios
          .post(
            "https://bsa-live-sales-api-gateway-5mbe41bd.nw.gateway.dev/v1/lots",
            prepData.value,
            {
              headers: { Authorization: "Bearer " + token },
            }
          )
          .then((r) => {
            axios.post(
              "https://bsa.co.za/wp-json/wp_event_lots/v1/lot-action",
              {
                id: prepData.value.id,
                price: prepData.value.soldPrice,
                released_by: prepData.value.buyer,
                reserve: prepData.value.reservePrice,
                action:
                  prepData.value.type == "NO_SALE"
                    ? "NOT_SALE"
                    : prepData.value.type,
              },
              {
                headers: { Authorization: "Bearer " + store.state.apiToken },
              }
            );
            lotNum.value = "";
            type.value = "SALE";
            priceSold.value = "";
            buyer.value = "";
            reservePrice.value = "-";
            recall.value = null;
            recallttamp.value = null;
            // Update Wordpress
          })
          .then((res) => {
            // console.log(res)
          })
          .catch((err) => (terror.value = err.response.data.message));
      });
    };

    const recallLot = () => {
      if (lotNum.value) {
        terror.value = null;
        const getRecall = livedata.value.find(
          (item) => item.lotNumber == parseInt(lotNum.value)
        );
        if (getRecall) {
          lotNum.value = getRecall.lotNumber;
          type.value = getRecall.type;
          priceSold.value = getRecall.soldPrice;
          buyer.value = getRecall.buyer;
          reservePrice.value = getRecall.reservePrice;
          recall.value = getRecall.type;
          recallttamp.value = getRecall.timestamp;
          // console.log(recall.value);
        } else {
          terror.value = "No Transaction has been made for this Lot";
        }
      } else {
        terror.value = "Lot Number is required to recall a lot";
      }
    };
    onMounted(() => {
      if (!store.state.apiToken) {
        user.value
          .getIdToken()
          .then((token) =>
            axios.get(
              "https://bsa-live-sales-api-gateway-5mbe41bd.nw.gateway.dev/v1/auth/wp-token",
              {
                headers: { Authorization: "Bearer " + token },
              }
            )
          )
          .then((res) => {
            // console.log(res);
            store.commit("setApiToken", res.data.token);
          });
      }
    });

    const lots = computed(() => {
      return livedata.value
        .filter((item) => !item.current && item.type == "SALE")
        .slice(0, 5);
    });

    const previousBuyers = computed(() => {
      if (buyer.value) {
        // console.log(buyer.value);

        const buyers = [
          ...new Set(
            livedata.value
              // .filter((item) => !item.current && item.type == "SALE")
              .filter((item) => {
                // console.log(item);
                if (!item.current && item.type == "SALE") {
                  return buyer.value
                    .toLowerCase()
                    .split(" ")
                    .every((v) => item.buyer.toLowerCase().includes(v));
                }
              })
              .map((a) => a.buyer)
          ),
        ];
        return buyers;
      } else {
        return [];
      }
    });

    const clearBuyerResults = () => {
      setTimeout(function() {
        buyerFocus.value = false;
      }, 300);
    };

    const currentLot = computed(() => {
      if (livedata.value.length) {
        return wpdata.value.find(
          (obj) => obj.id === livedata.value.find((item) => item.current)?.id
        );
      }
    });

    return {
      cLotId,
      clerror,
      setCurrentLot,
      recallLot,
      recall,
      lotNum,
      type,
      priceSold,
      buyer,
      reservePrice,
      lots,
      previousBuyers,
      createTrans,
      terror,
      logOutUser,
      sidebarOpen,
      documents,
      livedata,
      currentLot,
      typeChanged,
      buyerFocus,
      clearBuyerResults,
    };
  },
};
</script>
