import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCt1rjbEgQw3YUaRulPM6cU_jHepR7IR0Y",
  authDomain: "bsa-live-sales.firebaseapp.com",
  projectId: "bsa-live-sales",
  storageBucket: "bsa-live-sales.appspot.com",
  messagingSenderId: "440398862761",
  appId: "1:440398862761:web:f19c39e8561c7bf8710cb8",
  measurementId: "G-YLEJWZ83RT",
};

firebase.initializeApp(firebaseConfig);

const AppAuth = firebase.auth();
const AppFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { AppAuth, AppFirestore, timestamp };
